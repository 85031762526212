import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class PermisionService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getModulesPermission(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getModulesPermission, ...args)
    }

    getPermissionProfiles(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getPermissionProfiles, ...args)
    }

    createPermissionProfile(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createPermissionProfile, ...args)
    }

    updatePermissionProfile(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.updatePermissionProfile, ...args)
    }

    deletePermissionProfile(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.deletePermissionProfile, ...args)
    }
}

function usePermisionService() {
  const permissionService = new PermisionService()

  return {
    permissionService,
  }
}

const { permissionService } = usePermisionService()
export default permissionService
