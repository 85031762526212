<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :options="PageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            @click="NavigateAccessLevelProfile(0)"
          >
            Add Access Level
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search..."
              @input="handleSearch"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="tableProfiles"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      @sort-changed="onSortChange"
    >
      <!-- Column: Patient -->
      <template #cell(Name)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <template #cell(Actions)="data">
        <span class="text-nowrap">
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="NavigateAccessLevelProfile(data.item.Id)"
          />
          <!-- data.item.IdUser -->
          <feather-icon
            icon="TrashIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="DeleteAccessLevel(data.item.Id)"
          />
        </span>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BPagination, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import permissionServices from '@core/services/permissions/permissionService'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    vSelect,
    BTable,
  },
  data() {
    return {
      fields: [{ key: 'Name', label: 'Name Level', sortable: true }, { key: 'Actions' }],
      items: [],
      perPage: 10,
      PageOptions: [10, 25, 50, 100],
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
    }
  },
  watch: {
    perPage() {
      this.getAccessLevel()
    },
  },
  created() {
    this.getAccessLevel()
  },
  methods: {
    getAccessLevel() {
      permissionServices.getPermissionProfiles({
        page: this.actualpage,
        rows: this.perPage,
        name: this.searchTerm,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
      }).then(response => {
        this.items = response.data.data
        this.FullCountEntries = response.data.data[0].full_count
        this.numPages = response.data.data[0].pages
        this.totalRows = this.FullCountEntries
        this.currentPage = this.actualpage
      })
    },
    DeleteAccessLevel(id) {
      Swal.fire({
        title: 'Attention',
        text: '¿Do you want to delete this access Level?',
        icon: 'warning',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      }).then(result => {
        if (result.isConfirmed) {
          permissionServices.deletePermissionProfile({
            id,
          }).then(() => {
            this.getAccessLevel()
            Swal.fire(
              'Success',
              'Deleted!',
              'success',
            )
          })
        }
      })
    },
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getAccessLevel()
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getAccessLevel()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getAccessLevel()
    },
    NavigateAccessLevelProfile(id) {
      this.$store.commit('access-level/setIdAccessLevel', id)
      this.$router.push({ name: 'access-level-profile' })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
